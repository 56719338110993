import { FC, useState } from "react";

import Page from "../components/layout/Page";
import NavContainer from "../components/layout/NavContainer";
import LeftNav from "../components/LeftNav";
import UploadFile from "../components/Admin/UploadFile";
import UserTable from "../components/Admin/UserTable";
import BulkClosuresManager from "../components/Admin/BulkClosuresManager";
import Export from "../components/Admin/Export";

const Admin: FC = (): JSX.Element => {
  const bulkCarriersPage = "Bulk Carrier Upload"
  const bulkClosuresPage = "Bulk Closures Manager"
  const exportPage = "Export"
  const userConfigPage = "User Configuration"

  const pages: string[] = [bulkCarriersPage, bulkClosuresPage, exportPage, userConfigPage];
  const [currentPage, setCurrentPage] = useState(pages[0]);

  const findPageContent = (currentPage: string) => {
    switch (currentPage) {
      case bulkCarriersPage:
        return <UploadFile />;
      case bulkClosuresPage:
        return <BulkClosuresManager />;
      case exportPage:
        return <Export />;
      case userConfigPage:
        return <UserTable />;
      default:
        return null;
    }
  };

  const setPage = (page: string): void => {
    setCurrentPage(page);
  };

  return (
    <Page>
      <NavContainer>
        <LeftNav items={pages} title={"Admin"} setPage={setPage} currentPage={currentPage} />
        <div className="p-10 w-full">
          <h1 className={"mb-2"}>{currentPage}</h1>
          {findPageContent(currentPage)}
        </div>
      </NavContainer>
    </Page>
  );
};

export default Admin;
