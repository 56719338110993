import React, { FC } from "react";

import { CarrierDetails } from "../../lib/models";
import { carrierInputValidationData } from "../../utils/validation";
import DynamicInput from "./DynamicInput";

export interface CarrierDetailsFieldsetProps {
  carrierDetails: CarrierDetails;
  carrier: string;
  handleUpdateCarrierDetails: (carrier: string, name: string, value: string | boolean | number) => void;
}

export const handlePrePopulatedFields = (nameValuePair: any[], carrier: string) => {
  if (carrierInputValidationData[carrier]?.[nameValuePair[0]]?.prePopulatedName && nameValuePair[1] === "") {
    nameValuePair[1] = carrierInputValidationData[carrier][nameValuePair[0]].prePopulatedName;
  }
};

export const formatString = (_string: string) => {
  let string = _string.charAt(0).toUpperCase() + _string.slice(1);
  return string.split(/(?=[A-Z])/).join(" ");
};

const CarrierDetailsFieldset: FC<CarrierDetailsFieldsetProps> = ({
  carrierDetails,
  carrier,
  handleUpdateCarrierDetails,
}): JSX.Element => {
  return (
    <div className={"mb-2 px-5 overflow-hidden transition h-full"}>
      {Object.entries(carrierDetails[carrier]).map((pairs, id, originalArray) => {
        handlePrePopulatedFields(pairs, carrier);

        return (
          <div key={id} className={`py-3 w-full flex justify-between ${id !== originalArray.length - 1 && "border-b"}`}>
            <label htmlFor={pairs[0]} className={"mr-4 whitespace-nowrap w-48"}>
              {formatString(pairs[0])}
            </label>
            <DynamicInput
              name={pairs[0]}
              value={pairs[1]}
              carrier={carrier}
              handleUpdateCarrierDetails={handleUpdateCarrierDetails}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CarrierDetailsFieldset;
