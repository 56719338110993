import { Auth } from "aws-amplify";

export * from "@aws-amplify/ui-react";
export { awsSession };

/**
 * Gets the session of current user.
 * Throws Exception if current user is not logged in.
 */
const awsSession = async () => {
  const user = await Auth.currentSession();
  const idToken = user.getIdToken();
  return {
    username: idToken.payload["cognito:username"],
    email: idToken.payload.email,
  };
};
