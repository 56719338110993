import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBranchDetails } from "../services/BranchService";
import { getCarrierDetails, saveCarrierDetails } from "../services/CarrierService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { BranchDetails, CarrierDetails } from "../lib/models";
import { branchMetaData } from "../utils/branchMetaData";

import { Archive, Check } from "heroicons-react";
import Page from "../components/layout/Page";
import ClipLoader from "react-spinners/ClipLoader";
import BranchInfo from "../components/BranchManagement/BranchInfo";
import NavContainer from "../components/layout/NavContainer";
import LeftNav from "../components/LeftNav";
import LastNotifiedField from "../components/BranchManagement/LastNotifiedField";
import { handleValidations } from "../utils/validation";
import CarrierDetailsFieldset from "../components/BranchManagement/CarrierDetailsFieldset";
import { AxiosError } from "axios";

interface ParamTypes {
  branchId: string;
}

const getCarriers = (data: CarrierDetails) => {
  return {
    hubCounter: data.hubCounter,
    dpd: data.dpd,
  };
};

// If a branch has no courier information, new records are created by setting the HK and SK values to the branch id (TODO: this should be handled by the backend)
const addMissingCarrierIds = (carrierData: CarrierDetails, branchId: string) => {
  if (!carrierData.HK || carrierData.HK === "") {
    carrierData.HK = branchId;
    carrierData.SK = `branchConfig-V0-${branchId}`;
  }
  return carrierData;
};

const BranchManagementPage: FC = (): JSX.Element => {
  const [submitEnabled, setSubmitEnabled] = useState<{ [key: string]: boolean }>(null);
  const [carrierDetails, setCarrierDetails] = useState<CarrierDetails>(null);
  const [newCarrierDetails, setNewCarrierDetails] = useState<CarrierDetails>(null);
  const [branchDetails, setBranchDetails] = useState<BranchDetails>(null);
  const [pageTitles, setPageTitles] = useState<{ [key: string]: string }>(null);
  const [currentPage, setCurrentPage] = useState("Branch Details");
  const [loading, setLoading] = useState(false);
  const { branchId } = useParams<ParamTypes>();
  const history = useHistory();

  useEffect(() => {
    if (history && branchId) {
      const getData = async () => {
        await Promise.all([getCarrierDetails(branchId), getBranchDetails(branchId)]).then(
          ([carrierData, branchData]) => {
            carrierData = addMissingCarrierIds(carrierData, branchId);
            setCarrierDetails(carrierData);
            setBranchDetails(branchData);

            const keys: string[] = Object.keys(getCarriers({ ...carrierData }));
            generatePageTitles(keys);
            configureSubmitEnabled(keys);
          },
          (error: AxiosError) => {
            toast.error(
              <div>
                <p>Branch/Carrier details not found!</p>
                <small>{error?.message}</small>
              </div>
            );
            history.push("/branch-search");
          }
        );
      };

      getData();
    }
  }, [branchId, history]);

  useEffect(() => {
    if (carrierDetails && !newCarrierDetails) {
      const carrierFields: any = {};

      Object.keys(getCarriers({ ...carrierDetails })).forEach((carrierKey) => {
        const carrierField = carrierDetails[carrierKey];
        if (typeof carrierField === "object") {
          const castCarrierField: object = carrierField;
          carrierFields[carrierKey] = { ...castCarrierField };
        }
      });

      setNewCarrierDetails({ ...carrierFields });
    }
  }, [carrierDetails, newCarrierDetails]);

  const generatePageTitles = (keys: string[]) => {
    const titleKeyPair: string[][] = keys.map((key) => [branchMetaData[key].displayName, key]);
    titleKeyPair.unshift(["Branch Details", "Branch Details"]);
    setPageTitles(Object.fromEntries(titleKeyPair));
  };

  const configureSubmitEnabled = (keys: string[]) => {
    const keyValuePair: (string | boolean)[][] = keys.map((key) => [key, false]);
    setSubmitEnabled(Object.fromEntries(keyValuePair));
  };

  const submitFormData = (e: React.MouseEvent<HTMLInputElement>) => {
    setLoading(true);

    const postToApi = async () => {
      const key: string = pageTitles[currentPage];
      const updatedCarrierDetails = { ...carrierDetails, ...{ [key]: newCarrierDetails[key] } };
      updatedCarrierDetails.carrierUpdateType = key;

      await saveCarrierDetails(updatedCarrierDetails).then(
        () => {
          toast.success(`Successfully updated ${currentPage} information for ${branchDetails?.name} branch`);
          setSubmitEnabled({ ...submitEnabled, ...{ [key]: false } });
          setCarrierDetails(updatedCarrierDetails);
          setLoading(false);
        },
        (error: AxiosError<any>) => {
          toast.error(
            <>
              <p>{error?.message}</p>
              <p>
                {error?.response?.data?.message} {branchDetails.name}
              </p>
            </>
          );
        }
      );
    };
    postToApi();
  };

  const handleUpdateCarrierDetails = (carrier: string, name: string, value: string | number | boolean) => {
    !handleValidations(carrier, name, value)
      ? setSubmitEnabled({ ...submitEnabled, ...{ [carrier]: true } })
      : setSubmitEnabled({ ...submitEnabled, ...{ [carrier]: false } });

    const originalDetails = newCarrierDetails[carrier];
    if (typeof originalDetails === "object") {
      const castDetails: object = originalDetails;
      const updatedCarrier = { ...castDetails };
      updatedCarrier[name] = value;
      setNewCarrierDetails({ ...newCarrierDetails, ...{ [carrier]: updatedCarrier } });
    }
  };

  const setPage = (page: string): void => {
    setCurrentPage(page);
  };

  const carrierSupportsNotification = (carrier: string): boolean => {
    return carrier === "hubCounter";
  };

  const carrierMapping = {
    DPD: "dpd",
    "Amazon Hub Counter": "hubcounter",
  };

  return (
    <Page>
      {branchDetails && pageTitles ? (
        <div>
          <div>
            <button
              aria-label={"Back to search page"}
              onClick={() => {
                history.push(`/branch-search`);
              }}
              className="flex h-10 px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red"
            >
              Back
            </button>
          </div>
          <div className="mt-4">
            <NavContainer>
              <LeftNav
                items={Object.keys(pageTitles)}
                title={"Branch Management"}
                setPage={setPage}
                currentPage={currentPage}
              />
              <div className="p-10 w-full">
                <h1>{currentPage}</h1>
                {currentPage === "Branch Details" ? (
                  <>
                    <BranchInfo
                      branchDetails={branchDetails}
                      carrierDetails={carrierDetails}
                      setCarrierDetails={setCarrierDetails}
                    />
                  </>
                ) : (
                  <div>
                    <div className="flex flex-row justify-between">
                      {carrierSupportsNotification(currentPage) ? (
                        <LastNotifiedField
                          LastNotifiedDate={carrierDetails.sentDateTime}
                          LastNotifiedStatus={carrierDetails.sentStatus}
                        ></LastNotifiedField>
                      ) : (
                        <div></div>
                      )}
                      <button
                        type="button"
                        className="inline-flex items-center btn btn-clear"
                        onClick={() => {
                          if (currentPage in carrierMapping) {
                            history.push(`/branch-manager/${branchId}/history/${carrierMapping[currentPage]}`);
                          }
                        }}
                      >
                        <Archive size={18} className="mr-2" /> History
                      </button>
                    </div>
                    <div className={"border rounded-lg bg-white px-7 pt-9 pb-7 mt-5"}>
                      <CarrierDetailsFieldset
                        carrier={pageTitles[currentPage]}
                        carrierDetails={newCarrierDetails}
                        handleUpdateCarrierDetails={handleUpdateCarrierDetails}
                      />
                      <button
                        type="button"
                        onClick={submitFormData}
                        disabled={!submitEnabled[pageTitles[currentPage]]}
                        className={"btn btn-success mt-8 flex ml-auto disabled:opacity-50"}
                      >
                        {loading ? <ClipLoader color={"#fff"} size={28} /> : <Check size={28} />}
                        <p className={"text-lg ml-2"}>Update {currentPage}</p>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </NavContainer>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <ClipLoader color={"#ba112d"} size={35} />
        </div>
      )}
    </Page>
  );
};

export default BranchManagementPage;
