import { FC, useState, useEffect } from "react";

import Auth from "@aws-amplify/auth";
import { saveBulkClosures } from "../../services/BulkClosuresService";
import { AxiosError } from "axios";
import { BulkClosures } from "../../lib/models";

import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Check } from "heroicons-react";

const BulkClosuresManager: FC = () => {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [textareaValue, setTextareaValue] = useState<string>("")
  const [idList, setIdList] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [lastSubmittedIds, setLastSubmittedIds] = useState<string[]>([]);
  const [failedIds, setFailedIds] = useState<string[]>([]);

  const handleConvertToArray = (value: string) => {
    const idArray: string[] = value.replace(/\s/g, "").split(",")
    setIdList(idArray);
  }

  const ukDateObjToUsaString = (dateObject: Date) => {
    return dateObject?.toLocaleString(`en-CA`).slice(0, 10);
  };

  const submitFormData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    const bulkClosures: BulkClosures = {
      startDate: ukDateObjToUsaString(startDate),
      endDate: ukDateObjToUsaString(endDate),
      branchIds: idList,
    }
    const submitData = async () => {
      const token = await Auth.currentSession();

      await saveBulkClosures(bulkClosures, token.getIdToken().getJwtToken()).then(
        (res: { failedIds: string[] | null } ) => {
          setLoading(false);
          let succesfulIds: string[] = [...idList]
          if (res.failedIds) {
            succesfulIds = idList.filter(function (el) {
              return res.failedIds.indexOf(el) < 0;
            });
            setFailedIds(res.failedIds);
            toast.error(
              <div>
                <p>Failed to update branch ids:</p>
                <small>{res.failedIds.join(", ")}</small>
              </div>
            );
          } else {
            setFailedIds([]);
          }
          setLastSubmittedIds([...idList]);
          setTextareaValue("");
          if (!res.failedIds || (res.failedIds && res.failedIds.length !== idList.length)) {
            toast.success(
              <div>
                <p>Successfully updated branch ids:</p>
                <small>{succesfulIds.join(", ")}</small>
              </div>
            );
          }
        },
        (error: AxiosError) => {
          setLoading(false);
          toast.error(
            <div>
              <p>Something went wrong with bulk closures endpoint</p>
              <small>{error?.message}</small>
            </div>
          );
        }
      );
    };

    submitData();
  }

  useEffect(() => {
    if (!!startDate && !!endDate && idList[0]?.length >= 7) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [startDate, endDate, idList])

  useEffect(() => {
    handleConvertToArray(textareaValue);
  }, [textareaValue])

  return (
    <section aria-label={"Bulk branch closures manager"}>
      <form name="Set and submit exceptional branch closures" onSubmit={e => submitFormData(e)}>
        <button type={"submit"} disabled={true} className={"hidden"} aria-hidden={"true"}></button>

        <div>
          <div className={"w-full flex justify-around"}>
            <div className={"flex flex-col w-3/5 pr-2"}>
              <label htmlFor={"id-list"}>Add comma separated Branch IDs:</label>
              <textarea
                value={textareaValue}
                id={"id-list"}
                name={"id-list"}
                className={"w-full resize-none h-full"}
                placeholder={"123456X,6543210,224466X"}
                rows={4}
                onChange={e => setTextareaValue(e.target.value)}
              >
              </textarea>
            </div>
            <div className={"w-2/5 px-2"}>
              <label htmlFor={"startDate"}>From:</label>
              <DatePicker
                id={"startDate"}
                name={"startDate"}
                className="mb-5 text-black"
                placeholderText="First Closed Date..."
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
              <label htmlFor={"endDate"}>Until:</label>
              <DatePicker
                id={"endDate"}
                name={"endDate"}
                className="text-black"
                placeholderText="Final Closed Date..."
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          {lastSubmittedIds.length > 0 &&
            <p aria-label={"successful ids"} className={"text-xs text-gray-500"}>
              Last submitted IDs: {lastSubmittedIds.join(", ")}
            </p>
          }
          {failedIds.length > 0 &&
            <p aria-label={"failed ids"} className={"text-xs text-pol-red"}>
              Failed IDs: {failedIds.join(", ")}
            </p>
          }


        </div>

        <div className={"flex justify-end"}>
          <button
            type="submit"
            disabled={!submitEnabled}
            className={"btn btn-success mt-8 flex ml-auto disabled:opacity-50"}
          >
            {loading ? <ClipLoader color={"#fff"} size={28} /> : <Check size={28} />}
            <p className={"text-lg ml-2"}>Update Branches</p>
          </button>
        </div>
      </form>
    </section>
  );
};

export default BulkClosuresManager;
