import React, { FC, useEffect } from "react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { useHistory, useLocation } from "react-router-dom";
import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";

const DEFAULT_REDIRECT: string = "/";

const Login: FC = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  let returnUrl = location.search
    .substring(1)
    .split("&")
    .find((s) => s.startsWith("return="));

  returnUrl = returnUrl ? returnUrl.split("=")[1] : DEFAULT_REDIRECT;

  useEffect(() => {
    onAuthUIStateChange((newAuthState: AuthState) => {
      if (newAuthState === AuthState.SignedIn) {
        history.replace(returnUrl as string);
      }
    });
  }, [history, returnUrl]);

  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" usernameAlias="email" headerText="Sign in to PUDO" hideSignUp />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
};

export default Login;
