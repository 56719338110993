export const castValueToNewType = (value: string | number | boolean) => {
  switch (typeof (value)) {
    case "string":
      const stringValue: string = value;
      return stringValue;
    case "number":
      const numberValue: number = value;
      return numberValue;
    case "boolean":
      const booleanValue: boolean = value;
      return booleanValue;
  }
}
