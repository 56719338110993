import { Env } from "../lib/EnvironmentVariables";
import { BulkClosures } from "../lib/models";
import { HttpClient } from "./Http/HttpClient";

const bulkClosuresEndpoint: string = "/bulk-closures";

export async function saveBulkClosures(closures: BulkClosures, auth: string): Promise<{ failedIds: string[] | null }> {
  const httpClient = new HttpClient(Env.PudoUrl, undefined, auth);
  return await httpClient.post(bulkClosuresEndpoint, closures);
}
