import { Env } from "../lib/EnvironmentVariables";
import { BranchDetails } from "../lib/models";
import { HttpClient } from "./Http/HttpClient";

const locationsEndpoint: string = "/locations";

const httpClient = new HttpClient(Env.LocationsUrl);

export async function getBranchDetails(branchId: string): Promise<BranchDetails> {
  return await httpClient.get(locationsEndpoint, { id: branchId });
}
