import React, { FC, useState, useEffect } from "react";

import { carrierInputValidationData } from "../../utils/validation";

export interface DynamicInputProps {
  name: string;
  value: string | number | boolean | unknown;
  carrier: string;
  handleUpdateCarrierDetails: (carrier: string, name: string, value: string | boolean | number) => void;
}

export const handleDisplayHint = (carrier: string, name: string) => {
  if (carrierInputValidationData[carrier]?.[name]?.hint?.()) {
    return (
      <p aria-label={"hint"} className={"text-xs italic text-gray-500"}>
        {carrierInputValidationData[carrier][name].hint()}
      </p>
    );
  }
};

const DynamicInput: FC<DynamicInputProps> = ({ name, value, carrier, handleUpdateCarrierDetails }): JSX.Element => {
  const [characterCount, setCharacterCount] = useState<number>(0);

  useEffect(() => {
    if (value && typeof value === "string") {
      setCharacterCount(value.length);
    }
  }, [value]);

  switch (typeof value) {
    case "boolean":
      return (
        <div className={"flex flex-col items-end"}>
          <input
            data-testid="dynamic-input"
            type="checkbox"
            aria-label={`checkbox for ${carrier} ${name}`}
            name={name}
            checked={value}
            className={"h-6 w-6 text-blue-500 rounded"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdateCarrierDetails(carrier, name, e.target.checked);
            }}
          />
          {handleDisplayHint(carrier, name)}
        </div>
      );
    case "number":
      return (
        <div className={"w-full flex flex-col items-end"}>
          <input
            data-testid="dynamic-input"
            type="number"
            aria-label={`number input for ${carrier} ${name}`}
            name={name}
            value={`${value}`}
            min={0}
            placeholder={"0"}
            className={"w-full border rounded py-1 px-3"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdateCarrierDetails(carrier, name, parseInt(e.target.value));
            }}
          />
          {handleDisplayHint(carrier, name)}
        </div>
      );
    case "string":
      return (
        <div className={"w-full flex flex-col items-end"}>
          <div className={"w-full relative"}>
            <input
              data-testid="dynamic-input"
              type="text"
              aria-label={`text input for ${carrier} ${name}`}
              name={name}
              value={`${value}`}
              placeholder={"Type here"}
              className={"w-full border rounded py-1 px-3 pt-2 pb-3"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCharacterCount(e.target.value.length);
                handleUpdateCarrierDetails(carrier, name, e.target.value);
              }}
            />
            <p className={"text-xs absolute text-gray-500 right-1 bottom-0.5"}>Characters: {characterCount}</p>
          </div>
          {handleDisplayHint(carrier, name)}
        </div>
      );
    default:
      return null;
  }
};

export default DynamicInput;
