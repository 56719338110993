import { Auth } from "aws-amplify";
import { Env } from "../lib/EnvironmentVariables";
import { CarrierDetails } from "../lib/models";
import { HttpClient } from "./Http/HttpClient";

const branchEndpoint: string = "/branch";
const carrierHistoryEndPoint: string = "/branch/history";

export async function getCarrierDetails(branchId: string): Promise<CarrierDetails> {
  const httpClient = await getHttpClient();
  return await httpClient.get(branchEndpoint, { id: branchId });
}

export async function saveCarrierDetails(carrierDetails: CarrierDetails): Promise<CarrierDetails> {
  const httpClient = await getHttpClient();
  return await httpClient.post(branchEndpoint, carrierDetails);
}

export async function getHistoricCarrierUpdates(branchId: string, carrierName: string): Promise<CarrierDetails[]> {
  const httpClient = await getHttpClient();
  return await httpClient.get(carrierHistoryEndPoint, { id: branchId, carrier: carrierName });
}

async function getHttpClient(): Promise<HttpClient> {
  const token = await Auth.currentSession();
  return new HttpClient(Env.PudoUrl, undefined, token.getIdToken().getJwtToken());
}
