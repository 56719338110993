import React, { FC, useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { PencilAltOutline, Check, X } from "heroicons-react";
import { useHistory } from "react-router-dom";
import { getBranchDetails } from "../../services/BranchService";
import { getCarrierDetails } from "../../services/CarrierService";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { searchValidation } from "../../utils/validation";
import SearchBox from "./SearchBox";
import { AxiosError } from "axios";

const BranchTable: FC = (): JSX.Element => {
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pending, setPending] = useState(false);
  const [noBranch, setNoBranch] = useState(false);
  const history = useHistory();

  const handleOnClick = useCallback(
    (_, data?): void => {
      history.push(`/branch-manager/${data.branchData.locationBusinessId}`);
    },
    [history]
  );

  const search = useCallback(
    async (e) => {
      setPending(true);
      setNoBranch(false);
      e.preventDefault();

      const errors = searchValidation(searchText);
      if (errors.length > 0) {
        errors.forEach((error) => {
          toast.error(error);
        });
        setPending(false);
        return;
      }

      await Promise.all([getCarrierDetails(searchText), getBranchDetails(searchText)]).then(
        ([carrierData, branchData]) => {
          setRows([{ carrierData, branchData }]);
          setPending(false);
        },
        (error: AxiosError) => {
          setPending(false);
          setNoBranch(true);
          setRows([]);
          toast.error(
            <div>
              <p>Branch/Carrier details not found!</p>
              <small>{error?.message}</small>
            </div>
          );
        }
      );
    },
    [searchText]
  );

  const handlePageChange = async () => {};

  const handleRowChange = async () => {};

  // Edit button in datatable
  const editButton = useCallback(
    (row): JSX.Element => {
      return (
        <>
          <button
            type="button"
            className="inline-flex items-center btn btn-clear"
            onClick={(): void => handleOnClick("Edit Device", row)}
          >
            <PencilAltOutline size={18} className="mr-2" /> Edit
          </button>
        </>
      );
    },
    [handleOnClick]
  );

  // memorized subcomponent for data table which created data table header including search
  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <div className="flex w-full py-4">
        <SearchBox
          value={searchText}
          handleChange={(e) => setSearchText(e.target.value)}
          handleSearch={(e) => search(e)}
        />
      </div>
    );
  }, [search, searchText]);

  // handles checking for no branch and changing row colour
  const conditionalRowStyles = [
    {
      when: (row) => row.branch_id === "",
      style: {
        backgroundColor: "seashell",
        color: "black",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "pink",
        },
      },
    },
  ];

  // Creates datatable columns, includes formatting of date and sortable bool
  const newCols = React.useMemo(
    () => [
      {
        name: "Branch Id",
        cell: (row) => row.branchData.locationBusinessId,
        sortable: true,
      },
      {
        name: "Branch Name",
        cell: (row) => row.branchData.name,
        sortable: true,
      },
      {
        name: "DPD",
        cell: (row) => (row.carrierData.dpd.active ? <Check /> : <X />),
        center: true,
      },
      {
        name: "Hub Counter",
        cell: (row) => (row.carrierData.hubCounter.active ? <Check /> : <X />),
        center: true,
      },
      {
        name: "Last Updated",
        selector: "updatedDateTime",
        cell: (row) => moment(row.updatedDateTime).format("l0"),
        sortable: true,
      },
      {
        name: "Edit",
        button: true,
        cell: (row) => editButton(row),
        right: true,
      },
    ],
    [editButton]
  );

  return (
    <>
      <DataTable
        className="data-table"
        // @ts-ignore: DataTable does not support typescript
        columns={newCols}
        data={rows}
        highlightOnHover
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowChange}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        conditionalRowStyles={conditionalRowStyles}
        defaultSortField="created_at"
        defaultSortAsc
        progressPending={pending}
        progressComponent={
          <div className={"p-8"}>
            <ClipLoader color={"#ba112d"} size={35} />
          </div>
        }
        noDataComponent={
          <h2 className={"p-8"}>
            {noBranch
              ? "No branch found with this FAD code - please make sure you've typed it correctly"
              : "Please search by FAD code to find a branch"}
          </h2>
        }
      />
    </>
  );
};

export default BranchTable;
