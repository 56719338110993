import { AxiosError } from "axios";
import { DownloadOutline } from "heroicons-react";
import { FC } from "react";
import { toast } from "react-toastify";
import { exportPending } from "../../services/PendingService";

var fileDownload = require("js-file-download");

const Export: FC = (): JSX.Element => {
  const downloadDpdUpdates = (): void => {
    downloadUpdate("dpd");
  };

  function downloadUpdate(carrierName: string) {
    exportPending(carrierName)
      .then((response) => {
        fileDownload(response, getFileName(carrierName));

        toast.success(
          <div>
            <p>Carrier details downloaded!</p>
          </div>
        );
      })
      .catch((error: AxiosError) => {
        toast.error(
          <div>
            <p>Unable to export carrier details!</p>
            <small>{error?.message}</small>
          </div>
        );
      });
  }

  function getFileName(carrierName: string): string {
    const date = new Date().toISOString().split("T")[0];
    return `${carrierName}_Data_${date}.xlsx`;
  }

  return (
    <div>
      <div>
        <button
          type="button"
          data-cy="add-modal-button"
          className="inline-flex items-center btn bg-pol-red text-white mt-5"
          onClick={(): void => downloadDpdUpdates()}
        >
          <DownloadOutline size={18} className="mr-2" />
          Download Pending DPD Updates
        </button>
      </div>
    </div>
  );
};

export default Export;
