import { TrashOutline, UploadOutline, X } from "heroicons-react";
import { FC, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CarrierDetails, ClosureDateRanges } from "../../lib/models";
import { toast } from "react-toastify";
import { saveCarrierDetails } from "../../services/CarrierService";
import { AxiosError } from "axios";
import DeleteDateRangeModal from "../BranchManagement/DeleteDateRangeModal";
import AddDateRangeModal from "../BranchManagement/AddDateRangeModal";
import Backdrop from "../layout/Backdrop";

interface CalanderModalProps {
  showDateModal: boolean;
  openModal: () => void;
  carrierDetails: CarrierDetails;
  setCarrierDetails: React.Dispatch<React.SetStateAction<CarrierDetails>>;
}

const CalendarModal: FC<CalanderModalProps> = ({ showDateModal, openModal, carrierDetails, setCarrierDetails }) => {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [exceptionalClosures, setExceptionalClosures] = useState(carrierDetails.exceptionalClosures || null);
  const [deleteDateRangeModal, setDeleteDateRangeModal] = useState<boolean>(false);
  const [createDateRangeModal, setCreateDateRangeModal] = useState<boolean>(false);
  const [dateRangeNumber, setDateRangeNumber] = useState<number>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const ukDateObjToUsaString = (dateObject: Date) => {
    return dateObject?.toLocaleString(`en-CA`).slice(0, 10);
  };

  const getAllDatesIncludedInaRange = (startDate: string, endDate: string, steps = 1) => {
    const arrayContainingAllDates: Date[] = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      arrayContainingAllDates.push(new Date(currentDate));
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return arrayContainingAllDates;
  };

  const arrayOfAllDateObjectsWithinClosureRanges = (exceptionalClosuresArray: ClosureDateRanges[]) => {
    const newArray: Date[] = [];
    exceptionalClosuresArray?.forEach((closureRange) => {
      const startDate = closureRange.startDate;
      const endDate = closureRange.endDate;
      newArray.push(...getAllDatesIncludedInaRange(startDate, endDate));
    });
    return newArray;
  };

  const sortArrayOfDateObjects = (array: Date[]) =>
    array.sort((a: Date, b: Date) => {
      return a.getTime() - b.getTime();
    });

  const convertDateObjectsToUniqueDateNumbers = (array: Date[]): number[] => {
    let unique = [];
    array.forEach((date) => {
      if (!unique.includes(date.getTime())) {
        unique.push(date.getTime());
      }
    });
    return unique;
  };

  const sortDatesIntoUniqueRanges = (array: number[]): ClosureDateRanges[] => {
    const twentyFourHoursInMilliSeconds: number = 86400000;
    let initialStartDate = array[0];
    let initialEndDate = array[array.length - 1];
    let newObject = {
      startDate: ukDateObjToUsaString(new Date(initialStartDate)),
      endDate: ukDateObjToUsaString(new Date(initialEndDate)),
    };
    let arrayOfNonOverlappingDates = [];
    for (let i: number = 1; i < array.length - 1; i++) {
      if (array[i + 1] - array[i] === twentyFourHoursInMilliSeconds) {
        continue;
      } else {
        newObject.endDate = ukDateObjToUsaString(new Date(array[i]));
        arrayOfNonOverlappingDates.push({ ...newObject });
        newObject.startDate = ukDateObjToUsaString(new Date(array[i + 1]));
        newObject.endDate = ukDateObjToUsaString(new Date(array[array.length - 1]));
      }
    }
    arrayOfNonOverlappingDates.push({ ...newObject });
    return arrayOfNonOverlappingDates;
  };

  const arrayOfSortedNonOverlappingDateRanges = (array: ClosureDateRanges[]) => {
    const allDatesFromAllRanges = arrayOfAllDateObjectsWithinClosureRanges(array);
    const sortedArray = sortArrayOfDateObjects(allDatesFromAllRanges);
    const filteredSortedDates = convertDateObjectsToUniqueDateNumbers(sortedArray);
    const arrayOfUniqueDateRanges = sortDatesIntoUniqueRanges(filteredSortedDates);
    return arrayOfUniqueDateRanges;
  };

  const postToApi = async (updatedCarrierData: CarrierDetails) => {
    await saveCarrierDetails(updatedCarrierData).then(
      () => {
        setSuccess(true);
        toast.success(`Successfully updated branch closures`);
        setCarrierDetails(updatedCarrierData);
        setCreateDateRangeModal(false);
        setDeleteDateRangeModal(false);
        setPending(false);
        setEndDate(null);
        setStartDate(null);
      },
      (error: AxiosError<any>) => {
        toast.error(
          <div>
            <p>Sorry, could not update branch details at this time</p>
            <small>{error?.message}</small>
          </div>
        );
      }
    );
  };

  const usaStringToUkStringConverter = (date: string) => {
    return new Date(date).toLocaleDateString(`en-GB`);
  };

  const handleAddDateRange = () => {
    setPending(true);
    const newClosureRange = {
      startDate: ukDateObjToUsaString(startDate),
      endDate: ukDateObjToUsaString(endDate),
    };
    const sortedNewDates = exceptionalClosures
      ? arrayOfSortedNonOverlappingDateRanges([...exceptionalClosures, newClosureRange])
      : [newClosureRange];
    const newData = { ...carrierDetails, exceptionalClosures: sortedNewDates };
    setExceptionalClosures(sortedNewDates);
    postToApi(newData);
  };

  const handleDeleteDateRange = (rangeToBeDeleted: number): void => {
    setPending(true);
    const updatedRangeOfDates = exceptionalClosures.filter(
      (exceptionalRange) => exceptionalRange !== exceptionalClosures[rangeToBeDeleted]
    );
    const newData = { ...carrierDetails, exceptionalClosures: updatedRangeOfDates };
    updatedRangeOfDates.length > 0 ? setExceptionalClosures(updatedRangeOfDates) : setExceptionalClosures(null);
    postToApi(newData);
  };

  const toggleDeleteDateRangeModal = () => {
    setDeleteDateRangeModal(!deleteDateRangeModal);
    setSuccess(false);
  };
  const handleOnClickDelete = (i: number): void => {
    setDateRangeNumber(i);
    toggleDeleteDateRangeModal();
  };
  const toggleAddDateRangeModal = () => {
    setCreateDateRangeModal(!createDateRangeModal);
    setSuccess(false);
  };
  const handleOnClickAdd = () => {
    toggleAddDateRangeModal();
  };
  const handleClick = () => {
    setEndDate(null);
    setStartDate(null);
    openModal();
  };

  return (
    <>
      {showDateModal ? (
        <Backdrop>
          <div className=" border-black border rounded-lg mx-auto my-10 w-3/4 bg-gray-100 overflow-x-hidden overflow-y-auto fixed inset-0">
            <div className="flex justify-between mb-10 items-center bg-white border-b border-gray-200 h-20">
              <h1 className="ml-10">Branch Closure Dates</h1>
              <button className="mr-10" type="button" onClick={handleClick} bb-5>
                <X />
              </button>
            </div>
            <div className="flex ">
              <div className="w-64 py-7 px-4 bg-pol-red h-screen text-center ">
                <div className="space-y-2">
                  <h1 className="font-extrabold py-2.5 px-4 text-white border-b-2 border-white mb-5">
                    Add/Update Closing Dates
                  </h1>
                  <DatePicker
                    className=" mb-5 text-black"
                    placeholderText="Initial closing date..."
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="space-y-2">
                  <DatePicker
                    className="text-black mb-10"
                    placeholderText="Final closing date..."
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <button
                  type="button"
                  className="inline-flex items-center btn btn-success disabled:opacity-50"
                  onClick={() => handleOnClickAdd()}
                  disabled={!(startDate && endDate) ? true : false}
                >
                  <UploadOutline size={18} className="mr-2" /> Submit Branch Closure
                </button>
              </div>
              {exceptionalClosures !== null ? (
                <div className="flex-grow mx-10 border rounded-lg px-7 pt-9 pb-7 bg-white">
                  <h1 className="text-pol-red font-extrabold text-center mb-5 underline">Upcoming Branch Closures</h1>
                  <table className="mx-auto w-full">
                    <thead>
                      <tr>
                        <th className="w-40 text-justify">Date Range</th>
                        <th className="w-20 text-justify">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {arrayOfSortedNonOverlappingDateRanges(exceptionalClosures).map((exceptionalDay, i) => (
                        <tr key={i} className="border-b-2">
                          <td className="font-medium">{`${usaStringToUkStringConverter(
                            exceptionalDay.startDate
                          )} - ${usaStringToUkStringConverter(exceptionalDay.endDate)}`}</td>
                          <td>
                            <button>
                              <TrashOutline className="text-pol-red" onClick={() => handleOnClickDelete(i)} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h1 className="mx-auto">No Upcoming Branch Closures</h1>
              )}
            </div>
          </div>
        </Backdrop>
      ) : null}
      {deleteDateRangeModal && (
        <DeleteDateRangeModal
          toggleDeleteDateRangeModal={toggleDeleteDateRangeModal}
          dateRangeNumber={dateRangeNumber}
          handleDeleteDateRange={handleDeleteDateRange}
          carrierDetails={carrierDetails}
          success={success}
          pending={pending}
        />
      )}
      {createDateRangeModal && (
        <AddDateRangeModal
          toggleAddDateRangeModal={toggleAddDateRangeModal}
          carrierDetails={carrierDetails}
          handleAddDateRange={handleAddDateRange}
          startDate={startDate}
          endDate={endDate}
          success={success}
          pending={pending}
        />
      )}
    </>
  );
};
export default CalendarModal;
