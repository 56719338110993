import React, { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TrashOutline, UserAddOutline } from "heroicons-react";
import ClipLoader from "react-spinners/ClipLoader";
import { getList, getGroup } from "../../services/CognitoService";
import Modal from "./Modal";
import DeleteModal from "./DeleteModal";
import { User, Users } from "../../lib/models";

const UserTable: FC = (): JSX.Element => {
  const [rows, setRows] = useState<Users | undefined>(undefined);
  const [isCreateUserModal, setIsCreateUserModal] = useState<boolean>(false);
  const [isDeleteUserModal, setIsDeleteUserModal] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<User | undefined>(undefined);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const pending: boolean = false;

  useEffect(() => {
    getRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateUserModal, isDeleteUserModal]);

  const getRows = async () => {
    const data = await getList();
    if (data.Users) {
      for (let i = 0; i < data.Users.length; i++) {
        const groups = await getGroup(data.Users[i].Username);
        if (groups.Groups[0]) {
          const userType = groups.Groups[0].GroupName;
          data.Users[i].Group = userType;
        } else {
          data.Users[i].Group = "Standard";
        }
      }
    }
    setEmailList(getEmailList(data));
    setRows(data);
  };

  const getEmailList = (data: Users): string[] => {
    const result = [];
    data.Users?.forEach((user) => {
      result.push(user.Username);
    });
    return result;
  };

  const toggleCreateUser = () => {
    setIsCreateUserModal(!isCreateUserModal);
  };
  const toggleDeleteUser = () => {
    setIsDeleteUserModal(!isDeleteUserModal);
  };

  const handleOnClick = (data: User): void => {
    setCurrentRow(data);
    toggleDeleteUser();
  };


  const getName = (email:string):string => {
    return email.slice(0,email.indexOf('@'))
      .split('.')
      .map(element => element[0].toUpperCase()+element.slice(1))
      .join(' ');
  }

  // Delete button in datatable
  const deleteButton = (row: User): JSX.Element => {
    return (
      <>
        <button
          type="button"
          className="inline-flex items-center btn btn-clear"
          onClick={(): void => handleOnClick(row)}
        >
          <TrashOutline size={16} className="mr-2" /> Delete
        </button>
      </>
    );
  };

  // memorized subcomponent for data table which created data table header including search
  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <>
        <div className="flex w-full py-4">
          <div className="flex-1 min-w-0">
            <div className="inline-flex">
              <button
                type="button"
                data-cy="add-modal-button"
                className="inline-flex items-center btn bg-pol-red text-white"
                onClick={(): void => toggleCreateUser()}
              >
                <UserAddOutline size={18} className="mr-2" /> Add User
              </button>
            </div>
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnClick]);

  // handles checking for no branch and changing row colour
  const conditionalRowStyles = [
    {
      when: (row) => row.branch_id === "",
      style: {
        backgroundColor: "seashell",
        color: "black",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "pink",
        },
      },
    },
  ];

  // Creates datatable columns, includes formatting of date and sortable bool
  const newCols = React.useMemo(
    () => [
      {
        name: "Name",
        cell: (row) => getName(row.Username),
      },
      {
        name: "User email",
        cell: (row) => row.Username,
      },
      {
        name: "User type",
        cell: (row) => row.Group,
      },
      {
        name: "Remove",
        button: true,
        cell: (row) => deleteButton(row),
        right: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div aria-label={"User configuration manager"}>
      {rows &&
        <DataTable

          className="data-table"
          // @ts-ignore: DataTable does not support typescript
          columns={newCols}
          data={rows.Users}
          highlightOnHover
          persistTableHead
          conditionalRowStyles={conditionalRowStyles}
          progressPending={pending}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressComponent={
            <div className={"p-8"}>
              <ClipLoader color={"#ba112d"} size={35} />
            </div>
          }
          noDataComponent={<h2 className={"p-8"}>Connection Error</h2>}
        />
      }
      { isCreateUserModal && <Modal close={toggleCreateUser} emailList={emailList} />}
      { isDeleteUserModal && <DeleteModal close={toggleDeleteUser} data={currentRow} />}
    </div>
  );
};

export default UserTable;
