export abstract class Env {
  static LocationsUrl = process.env.REACT_APP_LOCATIONS_URL;

  static PudoUrl = process.env.REACT_APP_PUDO_URL;

  static Cognito = {
    Region: process.env.REACT_APP_COGNITO_REGION,
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    UserPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  };

  static AmazonBucketName = process.env.REACT_APP_BUCKET_NAME;
  static AmazonDirectoryName = process.env.REACT_APP_DIR_NAME;
  static AmazonRegion = process.env.REACT_APP_REGION;
  static AmazonS3Url = process.env.REACT_S3_URL;
}
