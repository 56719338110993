import React, { FC } from "react";

interface BranchRadioProps {
  handleChange: (e) => void;
}

const BranchRadio: FC<BranchRadioProps> = ({ handleChange }) => {
  return (
    <div className="my-2 bb flex">
      <div>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="text-pol-red focus:ring-0 focus:ring-offset-0"
            name="radio"
            value="FE_Dpd"
            onChange={handleChange}
          />
          <span className="ml-2">DPD</span>
        </label>
      </div>
      <div>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="text-pol-red focus:ring-0 focus:ring-offset-0 ml-5"
            name="radio"
            value="FE_Hub_Counter"
            onChange={handleChange}
          />
          <span className="ml-2">Hub Counter</span>
        </label>
      </div>
    </div>
  );
};

export default BranchRadio;
