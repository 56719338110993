import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  AdminCreateUserCommand,
  AdminCreateUserCommandOutput,
  AdminDeleteUserCommand,
  AdminDeleteUserCommandOutput,
  AdminListGroupsForUserCommand,
  AdminListGroupsForUserCommandOutput,
  AdminAddUserToGroupCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { Env } from "../lib/EnvironmentVariables";
import { Users } from "../lib/models";

// TODO: This service is broken as it does not use any credentials at the moment, requires research on how to implement
const client = new CognitoIdentityProviderClient({
  region: Env.Cognito.Region,
});

export async function getList(): Promise<Users> {
  const command = new ListUsersCommand({ UserPoolId: Env.Cognito.UserPoolId });
  return await client.send(command).then(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );
}

export async function getGroup(user: string): Promise<AdminListGroupsForUserCommandOutput> {
  const command = new AdminListGroupsForUserCommand({ UserPoolId: Env.Cognito.UserPoolId, Username: user });
  return await client.send(command).then(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );
}

export async function addUser(email: string, userType: string): Promise<AdminCreateUserCommandOutput> {
  const command = new AdminCreateUserCommand({ UserPoolId: Env.Cognito.UserPoolId, Username: email });
  const addtoGroupCommand = new AdminAddUserToGroupCommand({
    GroupName: "Standard",
    UserPoolId: Env.Cognito.UserPoolId,
    Username: email,
  });
  return await client.send(command).then(
    async (response) => {
      await client.send(addtoGroupCommand);
      return response;
    },
    (error) => {
      return error;
    }
  );
}

export async function removeUser(email: string): Promise<AdminDeleteUserCommandOutput> {
  const command = new AdminDeleteUserCommand({ UserPoolId: Env.Cognito.UserPoolId, Username: email });
  return await client.send(command).then(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );
}
