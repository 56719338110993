import React, { FC } from 'react';


interface BackdropProps {
  children: JSX.Element
}

const Backdrop: FC<BackdropProps> = ({children}) => {

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      {children}
    </div>
  )
}

export default Backdrop