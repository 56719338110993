import { Auth } from "aws-amplify";
import { Env } from "../lib/EnvironmentVariables";
import { HttpClient } from "./Http/HttpClient";

const pendingEndpoint: string = "/pending";
const exportEndpoint: string = "/export";

export async function exportPending(carrier: string): Promise<any> {
  const httpClient = await getHttpClient();

  var url = `${carrier}${pendingEndpoint}${exportEndpoint}`;
  return await httpClient.get(url);
}

async function getHttpClient(): Promise<HttpClient> {
  const token = await Auth.currentSession();
  return new HttpClient(Env.PudoUrl, "application/vnd.ms-excel", token.getIdToken().getJwtToken(), "blob");
}
