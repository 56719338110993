import React, { FC, useState } from "react";
import { UserAddOutline, X } from "heroicons-react";
import { AdminCreateUserCommandOutput } from "@aws-sdk/client-cognito-identity-provider";
import ClipLoader from "react-spinners/ClipLoader";
import { addUser } from "../../services/CognitoService";
import { toast } from "react-toastify";
import Backdrop from "../layout/Backdrop";

interface ModalProps {
  close: () => void;
  emailList: string[];
}

const Modal: FC<ModalProps> = ({ close, emailList }) => {
  const [email, setEmail] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<AdminCreateUserCommandOutput | undefined>(undefined);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailList.includes(email)) {
      try {
        setPending(true);
        const data: AdminCreateUserCommandOutput = await addUser(email, "standard");
        if (data.User) {
          setNewUser(data);
          setSuccess(true);
          setPending(false);
          toast.success(`${newUser.User.Username} successfully added`);
        }
      } catch (e) {
        toast.error(e);
      }
    } else {
      toast.error(`${email} is already a user.`);
    }
  };

  return (
    <Backdrop>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex flex-row justify-end">
          <X className="hover:text-gray-500" onClick={() => close()} />
        </div>
        <div className="text-center">
          {!success && (
            <div className="mt-2 px-7 py-3">
              <h3 className="text-lg leading-6 mb-10 font-medium text-gray-900">
                Enter new user's Post Office email account
              </h3>
              <form className="flex flex-col items-center justify-center w-70" onSubmit={(e) => handleSubmit(e)}>
                <div className="flex-1  m-w-0">
                  <label htmlFor="search" className="sr-only">
                    Add User
                  </label>
                  <div className="relative mt-0 rounded-md ring-1 ring-gray-300">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <UserAddOutline size={18} className="mr-2" />
                    </div>
                    <input
                      type="email"
                      id="Add User"
                      placeholder="Add new user email..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-80 mb-5 py-2 pl-10 pr-3 border-gray-600 rounded-md focus:ring-pol-red-dark focus:border-pol-red-dark sm:text-sm"
                    />
                  </div>
                </div>
                <input
                  value="Submit"
                  type="submit"
                  className="block px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red ml-2"
                  role="menuitem"
                />
              </form>
            </div>
          )}
          {success && (
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Successfully added {newUser.User.Username}
              </h3>
              <div className="items-center px-4 py-3">
                <button
                  onClick={() => close()}
                  id="ok-btn"
                  className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                >
                  OK
                </button>
              </div>
            </div>
          )}

          {pending && (
            <div className={"p-8"}>
              <ClipLoader color={"#ba112d"} size={35} />
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default Modal;
