import { FC } from "react";

interface LastNotifiedFieldProps {
  LastNotifiedDate: string;
  LastNotifiedStatus: string;
}

enum NotificationStatus {
  Pending = "Pending",
  Sent = "Sent",
  Failed = "Failed",
  Error = "Error",
}

const LastNotifiedField: FC<LastNotifiedFieldProps> = (props: LastNotifiedFieldProps): JSX.Element => {
  function statusStyle() {
    let style = "inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white rounded";

    switch (props.LastNotifiedStatus) {
      case NotificationStatus.Pending:
        style += " bg-yellow-500";
        break;
      case NotificationStatus.Sent:
        style += " bg-green-500";
        break;
      case NotificationStatus.Failed:
      case NotificationStatus.Error:
        style += " bg-red-500";
        break;
    }

    return style;
  }

  function displayDate(dateString: string) {
    var date = new Date(dateString);
    return date.toLocaleString("en-GB");
  }

  return props.LastNotifiedDate && props.LastNotifiedStatus ? (
    <div className="mt-2">
      <p>
        <span>Carrier Last Notified: </span>
        <span>{displayDate(props.LastNotifiedDate)} </span>
        {<span className={statusStyle()}>{props.LastNotifiedStatus}</span>}
      </p>
    </div>
  ) : (
    <div></div>
  );
};

export default LastNotifiedField;
