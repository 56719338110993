import { FC } from "react";

interface LeftNavProps {
  items: string[];
  title: string;
  setPage: (page: string) => void;
  currentPage: string;
}

const LeftNav: FC<LeftNavProps> = ({ items, title, currentPage, setPage }) => {
  return (
    <div className={"bg-pol-red text-white w-64 space-y-6 py-7 px-4"}>
      <h1 className={"font-extrabold py-2.5 px-4"}>{title}</h1>
      <nav>
        {items &&
          items.map((item, i) => {
            return (
              <button
                key={item + i}
                aria-label={`Show ${item}`}
                onClick={() => setPage(item)}
                className={`block py-2.5 px-4 text-left hover:bg-pol-red-dark w-full ${
                  currentPage === item && "border-t-2 border-b-2 border-white"
                }`}
              >
                {item}
              </button>
            );
          })}
      </nav>
    </div>
  );
};

export default LeftNav;
