import { FC } from "react";
import Logo from "../../assets/images/logo.png";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import useUserStatus from "../../lib/hooks/useUserStatus";

const Header: FC = () => {
  const history = useHistory();

  const handleLogout = async () => {
    await Auth.signOut();
    history.push("/");
  };

  const handleAdmin = () => {
    history.push("/admin");
  };

  const userStatus = useUserStatus();
  const isLoggedIn = null !== userStatus;

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <Link to={"/"}>
                <img className="block w-auto h-12" src={Logo} alt="Post Office" />
              </Link>
            </div>
            <div className="flex ml-6 -my-px space-x-8">
              <h3 className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 border-pol-red">
                PUDO
              </h3>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <div className="relative ml-3">
              {isLoggedIn && (
                <button
                  onClick={handleAdmin}
                  className="inline-flex px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red mr-6"
                  role="menuitem"
                >
                  Admin
                </button>
              )}
              {isLoggedIn && (
                <button
                  onClick={handleLogout}
                  data-test="auth-flow-btn"
                  className="inline-flex px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red"
                  role="menuitem"
                >
                  Log Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
