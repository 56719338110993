import React, { FC, useState } from "react";
import { BranchDetails, CarrierDetails } from "../../lib/models";
import { PlusCircle } from "heroicons-react";
import CalendarModal from "../../components/BranchManagement/CalendarModal";

interface BranchInfoProps {
  branchDetails: BranchDetails;
  carrierDetails: CarrierDetails;
  setCarrierDetails: React.Dispatch<React.SetStateAction<CarrierDetails>>;
}

const BranchInfo: FC<BranchInfoProps> = ({ branchDetails, carrierDetails, setCarrierDetails }) => {
  const dayKeys = Object.keys(branchDetails.coreHours);
  const addressKeys = Object.keys(branchDetails.address).slice(0, -2);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);

  const openModal = () => {
    setShowDateModal((prev) => !prev);
  };

  return (
    <div className={"flex justify-around border rounded-lg bg bg-white px-7 pt-9 pb-7 mt-5"}>
      <div>
        <div className={"mb-2"}>
          <h2 className="text-pol-red font-bold underline mb-5" data-testid={"branch-title"}>
            {branchDetails?.name} Branch
          </h2>
          <p>Branch ID: {branchDetails?.locationBusinessId}</p>
        </div>
        <div className={"mb-2"}>
          <p className={" font-medium"}>Branch Address</p>
          {addressKeys.map((addressKey) => (
            <p key={addressKey}>{branchDetails?.address[addressKey]}</p>
          ))}
        </div>
      </div>
      <div>
        <h2 className=" text-pol-red text-center font-bold underline mb-5">Core Branch Hours</h2>
        <table>
          <thead>
            <tr>
              <th className={"w-40 text-justify"}>Day</th>
              <th className={"w-20 text-justify"}>Opening</th>
              <th className={"w-20 text-justify"}>Closing</th>
            </tr>
          </thead>
          <tbody>
            {dayKeys.map((day) =>
              branchDetails.coreHours[day].isOpen ? (
                <tr key={day}>
                  <td>{day}</td>
                  <td>{branchDetails?.coreHours[day].openTime}</td>
                  <td>{branchDetails?.coreHours[day].closeTime}</td>
                </tr>
              ) : (
                <tr key={day}>
                  <td>{day}</td>
                  <td className=" text-pol-red">Closed</td>
                  <td className=" text-pol-red">Closed</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <button
          type="button"
          data-cy="add-modal-button"
          className="inline-flex items-center btn bg-pol-red text-white mt-5"
          onClick={openModal}
        >
          <PlusCircle size={18} className="mr-2" />
          View/Modify Branch Closures
        </button>
        {carrierDetails.exceptionalClosures === null || carrierDetails.exceptionalClosures.length === 0 ? (
          <h3 className="text-green-600 font-bold">No Upcoming Closures</h3>
        ) : (
          <h3 className="text-pol-red font-bold">Upcoming Closures</h3>
        )}
        <CalendarModal
          showDateModal={showDateModal}
          openModal={openModal}
          carrierDetails={carrierDetails}
          setCarrierDetails={setCarrierDetails}
        />
      </div>
    </div>
  );
};

export default BranchInfo;
