import { FC } from "react";
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Pages
import Login from "./pages/Login";
import Header from "./components/layout/Header";
import BranchManagement from "./pages/BranchManagement";
import BranchSearch from "./pages/BranchSearch";
import Admin from "./pages/Admin";

//pre cdk fix
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Authenticated from "./components/authenticated";
import CarrierHistory from "./pages/CarrierHistory";

Amplify.configure(awsconfig);

const App: FC = () => {
  return (
    <Router>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        draggable={false}
        limit={2}
        role="alert"
        transition={Flip}
      />
      <Header />
      <Switch>
        <Redirect from="/" to="/branch-search" exact={true} />
        <Route path="/login" component={Login} />
        <Authenticated>
          <Route path="/branch-manager/:branchId" component={BranchManagement} exact={true} />
          <Route path="/branch-search" component={BranchSearch} />
          <Route path="/admin" component={Admin} />
          <Route path="/branch-manager/:branchId/history/:carrier" component={CarrierHistory} />
        </Authenticated>
      </Switch>
    </Router>
  );
};

export default App;
