import axios, { AxiosInstance, AxiosResponse, ResponseType } from "axios";

export class HttpClient {
  private client: AxiosInstance;

  constructor(baseUrl: string, contentType:string = "application/json", auth?: string, responseType?: ResponseType) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": contentType,
        "Authorization": auth,
      },
      responseType: responseType
    });

    this.setUpResponseInterceptor();
  }

  private setUpResponseInterceptor() {
    this.client.interceptors.response.use((config: AxiosResponse) => {
      return config.data;
    });
  }

  public async get<R>(url: string, params?: any): Promise<R> {
    return await this.client.get(url, { params: params });
  }

  public async post<T, R>(url: string, body?: T): Promise<R>  {
    return await this.client.post(url, body);
  }
}
