import { FC } from "react";
import Page from "../components/layout/Page";
import BranchTable from "../components/BranchSearch/BranchTable";

const Setup: FC = (): JSX.Element => {
  return (
    <Page>
      <BranchTable />
    </Page>
  );
};

export default Setup;
