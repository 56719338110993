import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Check, X } from "heroicons-react";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { getHistoricCarrierUpdates } from "../services/CarrierService";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";
import { CarrierDetails } from "../lib/models";

interface paramTypes {
  branchId: string;
  carrier: string;
}

const BranchTable: FC = (): JSX.Element => {
  const { branchId, carrier } = useParams<paramTypes>();
  const [rows, setRows] = useState<CarrierDetails[]>([]);
  const [pending, setPending] = useState(false);
  const [noHistoricData, setNoHistoricData] = useState(false);
  const history = useHistory();

  const usaStringToUkStringConverter = (date: string) => {
    return new Date(date).toLocaleDateString(`en-GB`);
  };

  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <div className="flex justify-between w-full py-4 px-5">
        <h1>{carrier.toUpperCase()} changes</h1>
        <button
          aria-label={"Back to search page"}
          onClick={() => {
            history.push(`/branch-manager/${branchId}`);
          }}
          className="flex h-10 px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red"
        >
          Back
        </button>
      </div>
    );
  }, [branchId, carrier, history]);

  const dpdCols = React.useMemo(
    () => [
      {
        name: "Exceptional Closures ",
        cell: (row) => {
          if (row.exceptionalClosures === null) {
            return <p className="text-pol-red">No Closures</p>;
          } else {
            return (
              <div>
                {row.exceptionalClosures?.map((row, i) => {
                  return (
                    <p key={i}>
                      {usaStringToUkStringConverter(row.startDate)} - {usaStringToUkStringConverter(row.endDate)}
                    </p>
                  );
                })}
              </div>
            );
          }
        },
        grow: "1.0",
        sortable: true,
      },
      {
        name: "Enrolled",
        cell: (row) => (row.dpd.enrolled ? <Check className="text-green-600" /> : <X className="text-pol-red" />),
        center: true,
      },
      {
        name: "Capacity",
        cell: (row) => row.dpd.capacity,
        center: true,
      },
      {
        name: "Active",
        cell: (row) => (row.dpd.active ? <Check className="text-green-600" /> : <X className="text-pol-red" />),
        center: true,
      },
      {
        name: "Last Updated",
        selector: "updatedDateTime",
        cell: (row) => moment(row.updatedDateTime).format("Do MMM YYYY, HH:HH"),
        sortable: true,
      },
    ],
    []
  );
  const amazonHubCounterCols = React.useMemo(
    () => [
      {
        name: "Exceptional Closures ",
        cell: (row) => {
          if (row.exceptionalClosures === null) {
            return <p className="text-pol-red">No Closures</p>;
          } else {
            return (
              <div>
                {row.exceptionalClosures?.map((row, i) => {
                  return (
                    <p key={i}>
                      {usaStringToUkStringConverter(row.startDate)} - {usaStringToUkStringConverter(row.endDate)}
                    </p>
                  );
                })}
              </div>
            );
          }
        },
        width: "0.2",
        sortable: true,
      },
      {
        name: "Enrolled",
        cell: (row) =>
          row.hubCounter.enrolled ? <Check className="text-green-600" /> : <X className="text-pol-red" />,
        center: true,
      },
      {
        name: "Capacity",
        cell: (row) => row.hubCounter.capacity,
        center: true,
      },
      {
        name: "Active",
        cell: (row) => (row.hubCounter.active ? <Check className="text-green-600" /> : <X className="text-pol-red" />),
        center: true,
      },
      {
        name: "Last Updated",
        selector: "updatedDateTime",
        cell: (row) => moment(row.updatedDateTime).format("Do MMM YYYY, HH:HH"),
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    setPending(true);
    const getData = async () => {
      await getHistoricCarrierUpdates(branchId, carrier).then(
        (carrierData) => {
          setRows(carrierData);
          setPending(false);
        },
        (error: AxiosError) => {
          setPending(false);
          setNoHistoricData(true);
          toast.error(
            <div>
              <p>Historic Branch Data Not Found</p>
              <small>{error?.message}</small>
            </div>
          );
        }
      );
    };
    getData();
  }, [branchId, carrier]);

  const conditionalRowStyles = [
    {
      when: (row) => row.branch_id === "",
      style: {
        backgroundColor: "seashell",
        color: "black",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "pink",
        },
      },
    },
  ];

  return (
    <div className="border mx-10 my-10">
      <DataTable
        className="data-table"
        // @ts-ignore: DataTable does not support typescript
        columns={carrier === "dpd" ? dpdCols : amazonHubCounterCols}
        data={rows}
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        conditionalRowStyles={conditionalRowStyles}
        defaultSortFieldId={5}
        defaultSortAsc={false}
        progressPending={pending}
        progressComponent={
          <div className={"p-8"}>
            <ClipLoader color={"#ba112d"} size={35} />
          </div>
        }
        noDataComponent={<h2 className={"p-8"}>{noHistoricData ? "No historic data for this branch" : null}</h2>}
      />
    </div>
  );
};

export default BranchTable;
