import { toast } from "react-toastify";
import { castValueToNewType } from "../utils/castValueToNewType";

const BRANCH_ID_REGEXP = new RegExp(/^\d{6}([a-z]|\d)$/i);

export const searchValidation = (searchString: string): string[] => {
  const errors: string[] = [];
  if (!BRANCH_ID_REGEXP.test(searchString)) {
    errors.push("Format Error: FAD requires 6 digits and 1 letter or 7 digits e.g. 000000X or 0000000");
  }

  return errors;
};

const validateStringStart = (value: string, comparer: string): string => {
  if (!value.startsWith(comparer)) {
    return `Field must start with "${comparer}"`;
  }
};

const validateStringLength = (value: string, comparer: number): string => {
  if (value.length > comparer) {
    return `Field must be less than ${comparer} characters`;
  }
};

export const carrierInputValidationData = {
  hubCounter: {
    branchName: {
      prePopulatedName: "Amazon Hub Counter - Post Office",
      maxCharacters: 50,
      hint: function () {
        return `Must begin with "${this.prePopulatedName}" and must be less than 50 characters`;
      },
      validationMethods: function () {
        return [
          (value: string) => {
            return validateStringStart(value, this.prePopulatedName);
          },
          (value: string) => {
            return validateStringLength(value, this.maxCharacters);
          },
        ];
      },
    },
  },
};

export const handleValidations = (carrier: string, name: string, value: string | number | boolean): boolean => {
  let hasErrors: boolean = false;
  let error: string;

  const recastValue = castValueToNewType(value);
  if (carrierInputValidationData[carrier]?.[name]?.validationMethods?.()) {
    carrierInputValidationData[carrier][name]
      .validationMethods()
      .forEach((validationMethod: (value: string | number | boolean) => string, i: number) => {
        error = validationMethod(recastValue);
        toast.error(error, { toastId: `validation-${i + 1}` });
        error && (hasErrors = true);
      });
  }
  return hasErrors;
};
