import React, { FC, useState } from "react";
import { X } from "heroicons-react";
import ClipLoader from "react-spinners/ClipLoader";
import Backdrop from "../layout/Backdrop";
import { toast } from "react-toastify";
import { CarrierDetails } from "../../lib/models";

interface ModalProps {
  carrierDetails: CarrierDetails;
  toggleDeleteDateRangeModal: () => void;
  dateRangeNumber: number;
  handleDeleteDateRange: (i) => void;
  success: boolean;
  pending: boolean;
}
const DeleteDateRangeModal: FC<ModalProps> = ({
  carrierDetails,
  toggleDeleteDateRangeModal,
  dateRangeNumber,
  handleDeleteDateRange,
  success,
  pending,
}) => {
  const [branchId, setBranchId] = useState<string>("");
  const usaToUkStringConverter = (date: string) => {
    return new Date(date).toLocaleDateString(`en-GB`);
  };
  const startDate = carrierDetails.exceptionalClosures[dateRangeNumber]?.startDate;
  const endDate = carrierDetails.exceptionalClosures[dateRangeNumber]?.endDate;
  const startDateUk = usaToUkStringConverter(startDate);
  const endDateUk = usaToUkStringConverter(endDate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (branchId === carrierDetails.HK) {
        handleDeleteDateRange(dateRangeNumber);
      } else {
        toast.error("Doesn't match");
      }
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <Backdrop>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex flex-row justify-end">
          <X className="hover:text-gray-500" onClick={() => toggleDeleteDateRangeModal()} />
        </div>
        <div className="text-center">
          {!success && (
            <div className="mt-2 px-7 py-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Enter branch ID to remove closures: </h3>
              <h3 className="mb-10">
                {startDateUk} - {endDateUk}
              </h3>
              <form className="inline-flex" onSubmit={(e) => handleSubmit(e)}>
                <div className="flex-1 m-w-0">
                  <label htmlFor="search" className="sr-only">
                    Delete Range
                  </label>
                  <div className="relative mt-0 rounded-md ring-1 ring-gray-300">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
                    <input
                      type="Delete User"
                      id="Delete User"
                      placeholder="Enter branch ID..."
                      value={branchId}
                      onChange={(e) => setBranchId(e.target.value)}
                      className="block w-full py-2 pl-2 pr- border-gray-600 rounded-md focus:ring-pol-red-dark focus:border-pol-red-dark sm:text-sm"
                    />
                  </div>
                </div>
                <input
                  value="Submit"
                  type="submit"
                  className="block px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red ml-2"
                  role="menuitem"
                />
              </form>
            </div>
          )}
          {pending && (
            <div className={"p-8"}>
              <ClipLoader color={"#ba112d"} size={35} />
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default DeleteDateRangeModal;
