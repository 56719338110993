import React, { FC } from "react";
import { Search } from "heroicons-react";

interface SearchBoxProps {
  value: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch?: (e: any) => Promise<void>;
}

const SearchBox: FC<SearchBoxProps> = ({ value, handleChange, handleSearch }): JSX.Element => {
  return (
    <form className="inline-flex" onSubmit={handleSearch}>
      <div className="flex-1 m-w-0">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative mt-0 rounded-md ring-1 ring-gray-300">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Search size={18} className="mr-2" />
          </div>
          <input
            type="search"
            id="search"
            placeholder="Search FAD code..."
            value={value}
            onChange={handleChange}
            className="block w-full py-2 pl-10 pr-3 border-gray-600 rounded-md focus:ring-pol-red-dark focus:border-pol-red-dark sm:text-sm"
          />
        </div>
      </div>
      <input
        value="Search"
        type="submit"
        className="block px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red ml-2"
        role="menuitem"
      />
    </form>
  );
};

export default SearchBox;
