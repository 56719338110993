import { UploadOutline } from "heroicons-react";
import { FC, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import S3 from "react-aws-s3";
import BranchRadio from "./BranchRadio";
import { toast } from "react-toastify";
import { Env } from "../../lib/EnvironmentVariables";

interface File {
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

const bucketName: string = Env.AmazonBucketName;
const dirName: string = Env.AmazonDirectoryName;
const region: string = Env.AmazonRegion;
const s3Url: string = Env.AmazonS3Url;

const config = {
  bucketName: bucketName,
  dirName: dirName,
  region: region,
  s3Url: s3Url,
};

const UploadFile: FC = (): JSX.Element => {
  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  }, []);

  let { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
  const ReactS3Client = new S3(config);
  const [carrier, setCarrier] = useState<string>(null);
  const [uploadedFile, setUploadedFile] = useState<File>(null);

  const handleOnClick = (): void => {
    if (carrier && uploadedFile !== null) {
      ReactS3Client.uploadFile(acceptedFiles[0], `${carrier}-${uploadedFile.name}`)
        .then((data) => {})
        .catch((err) => alert(err));
    } else if (!carrier && uploadedFile === null) {
      toast.error("Please Select a Carrier Plus a File to Upload");
    } else if (!carrier) {
      toast.error("Please Select a Carrier");
    } else {
      toast.error("Please Select a File to Upload");
    }
  };

  const handleChange = (e): void => {
    setCarrier(e.target.value);
  };

  const removeFile = (): void => {
    setUploadedFile(null);
  };

  return (
    <section aria-label={"Bulk carrier details upload"} className="flex flex-col font-sans mt-8 mb-6">
      <div
        {...getRootProps({ className: "dropzone" })}
        className={
          "flex-1 flex flex-col items-center p-10 border-2 rounded-t-sm border-dashed border-gray-300 hover:border-blue-200 bg-gray-100 text-gray-400"
        }
      >
        <input {...getInputProps()} />
        <p>1. Drag 'n' drop some files HERE, or click HERE to select files to upload.</p>
        <p>2. Select a carrier from the carrier icons below.</p>
        <p>3. Click Upload to upload file.</p>
      </div>
      <div className="mt-5">
        <BranchRadio handleChange={handleChange} />
      </div>
      {uploadedFile !== null ? (
        <aside>
          <h4 className={"font-extrabold mt-4"}>Files</h4>
          <div>
            <div className="flex justify-between mb-5">
              <li className="inline-flex ">
                {uploadedFile.name} - {uploadedFile.size} bytes
              </li>
              <button
                className="inline-flex px-4 py-2 text-sm text-white hover:bg-opacity-60 bg-pol-red"
                onClick={removeFile}
                data-test="auth-flow-btn"
              >
                Remove File
              </button>
            </div>
          </div>
        </aside>
      ) : (
        <h3 className="text-pol-red font-bold">No files selected to upload</h3>
      )}
      <button
        type="button"
        data-cy="add-modal-button"
        className="inline-flex items-center btn bg-pol-red text-white w-1/5 mt-5"
        onClick={(): void => handleOnClick()}
      >
        <UploadOutline size={18} className="mr-2" />
        Upload File
      </button>
    </section>
  );
};

export default UploadFile;
